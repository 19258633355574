/* ROUTER */
import { Outlet } from "react-router-dom"

/* COMPONENTS */
import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import FloatingMenu from "../components/FloatingMenu/FloatingMenu"


export default function RootLayout() {
    return (
        <div className="rootLayout">
            <Navbar />
            <main>
                <Outlet />
            </main>
            <Footer />
            <FloatingMenu />
        </div>
    )
}