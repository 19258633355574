import { React, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './Navbar.module.css'
import logo from '../../resources/logo/LogotipoSQ_Azul_SinFondo.png'

export default function Navbar() {

    const location = useLocation();

    const routes = [
        { id: 1, name: "Inicio", url: "/", isActive: true },
        { id: 2, name: "Nosotros", url: "/about-us", isActive: false },
        { id: 3, name: "Políticas", url: "/politics", isActive: false },
        { id: 4, name: "Planes", url: "/plans", isActive: false }
    ]

    const [navBar, setNavBar] = useState(routes);
    const [hamMenu, setHamMenu] = useState(false);

    useEffect(() => {
        
        const values = [
            { id: 1, name: "Inicio", url: "/", isActive: true },
            { id: 2, name: "Nosotros", url: "/about-us", isActive: false },
            { id: 3, name: "Políticas", url: "/politics", isActive: false },
            { id: 4, name: "Planes", url: "/plans", isActive: false }
        ]

        values.map(value => (value.isActive = value.url === location.pathname));
        setNavBar(values);
        window.scrollTo({ top: 0, behavior: "smooth" })        
    }, [location]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlerHamMenuClick = () => {
        setHamMenu(!hamMenu);
    }

    return (
        <nav className={style['navbar-module']}>
            <div className={style['nav-container']}>
                <div className={style['nav-brand']}>
                    <Link to={"/"}>
                        <img src={logo} width={95} alt='SQ LOGO' />
                    </Link>
                </div>
                <div className={style['nav-items']}>
                    <ul className={style['navbar-nav']}>
                        {
                            navBar.map((item, key) => {
                                return (
                                    <li key={key} className={style['nav-item']}>
                                        <Link to={item.url} key={item.id} className={style['nav-link'] + ' ' + (item.isActive ? style.active : undefined)} title={item.name}>
                                            {item.name}
                                        </Link>
                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
                <div onClick={handlerHamMenuClick} className={style.hamburguer}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={style["hamburguer-menu"] + ' ' + (hamMenu && style['hamburger-active'])}>
                    <ul className={style["hamburguer-items"]}>
                        {
                            navBar.map((item, key) => {
                                return (
                                    <Link className={(item.isActive ? style["hamburguer-item-active"] : undefined)} to={item.url} title={item.name}>
                                        <li onClick={handlerHamMenuClick} key={key} className={style['hamburguer-item']}>
                                            {item.name}
                                        </li>
                                    </Link>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}