import styles from "./About.module.css"
import SQ from "../../resources/video/IMG_2392.mp4"
import ME from "../../resources/video/me.mp4"
import Carrousel from "../Carrousel/Carrousel";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from "react";

export default function About() {

    const videos = [SQ, ME];
    const [index, setIndex] = useState(0);

    const backArrowClick = () => {
        setIndex(index === 0 ? 0 : index - 1)
    }

    const fowardArrowClick = () => {
        setIndex(index === videos.length - 1 ? videos.length - 1 : index + 1)
    }

    return (
        <div className={styles.about}>
            <div className={styles["main-title"]}>
                <div className={styles["banner-main"]}></div>
                <div className={styles["main-body"]}>
                    <h1>¿Qu&eacute; es SQNutrici&oacute;n?</h1>
                    <p>SQ Nutrición se enfoca en empoderar a individuos activos y saludables</p>
                    <p> para alcanzar sus objetivos de bienestar de manera equilibrada y realista.</p>
                    <div className={styles.body}>
                        <h3>Nuestro pensamiento</h3>
                        <p className={styles['m-bottom']}>Para SQ la nutrición y el ejercicio son fundamentales para vivir una vida plena, pero también sabemos que no se trata de extremos ni de satanizar alimentos. En lugar de eso, promovemos un enfoque basado en la constancia, el trabajo y la disciplina.</p>
                        <h3>Nuestra filosof&iacute;a</h3>
                        <p className={styles['m-bottom']}>En SQ Nutrición, creemos que no hay atajos ni magia en el camino hacia el bienestar. Todo se reduce a la constancia y la disciplina en la nutrición y el entrenamiento. Te invitamos a unirte a nuestra comunidad SQ, donde encontrarás el apoyo y la guía necesarios para lograr tus metas de forma sostenible y disfrutar de una vida activa y saludable.</p>
                        <h3>Nuestra conciencia</h3>
                        <p>Nuestros servicios de nutrición se centran en enseñar a nuestros clientes la importancia de tomar decisiones informadas sobre su alimentación. No consideramos que las dietas deban ser extremas ni en eliminar por completo los alimentos que tanto disfrutás. En su lugar, diseñamos planes de alimentación personalizados que se adaptan a tu estilo de vida y objetivos, alentándote a disfrutar de una relación saludable con la comida mientras trabajas hacia tus metas de salud.</p>
                    </div>
                </div>
                <div className={styles["video-controls"]}>
                    <ArrowBackIosIcon onClick={backArrowClick} />
                    <video src={videos[index]} height={600} autoPlay controls loop></video>
                    <ArrowForwardIosIcon onClick={fowardArrowClick} />
                </div>
                <div className={styles['arrows-responsive']}>
                    <ArrowBackIosIcon onClick={backArrowClick} className={styles['arrow-responsive']} />
                    <ArrowForwardIosIcon onClick={fowardArrowClick} className={styles['arrow-responsive']} />
                </div>
                <div className={styles["body-responsive"]}>
                    <h3>Nuestro pensamiento</h3>
                    <p>Para SQ la nutrición y el ejercicio son fundamentales para vivir una vida plena, pero también sabemos que no se trata de extremos ni de satanizar alimentos. En lugar de eso, promovemos un enfoque basado en la constancia, el trabajo y la disciplina.</p>
                    <h3>Nuestra filosof&iacute;a</h3>
                    <p>En SQ Nutrición, creemos que no hay atajos ni magia en el camino hacia el bienestar. Todo se reduce a la constancia y la disciplina en la nutrición y el entrenamiento. Te invitamos a unirte a nuestra comunidad SQ, donde encontrarás el apoyo y la guía necesarios para lograr tus metas de forma sostenible y disfrutar de una vida activa y saludable.</p>
                    <h3>Nuestra conciencia</h3>
                    <p>Nuestros servicios de nutrición se centran en enseñar a nuestros clientes la importancia de tomar decisiones informadas sobre su alimentación. No consideramos que las dietas deban ser extremas ni en eliminar por completo los alimentos que tanto disfrutas. En su lugar, diseñamos planes de alimentación personalizados que se adaptan a tu estilo de vida y objetivos, alentándote a disfrutar de una relación saludable con la comida mientras trabajas hacia tus metas de salud.</p>
                </div>
            </div>
            {/* TODO: ADD THE MISSING INFORMATION, SARAY MUST PROVIDE IT */}
            {false &&
                <div className={styles.content + ' ' + styles['d-none']}>
                    <Carrousel />
                </div>
            }
        </div>
    );
}