import styles from './Footer.module.css'
import { Link } from 'react-router-dom'
import { WhatsApp, FacebookRounded, Instagram, Email } from '@mui/icons-material'
import GoogleDownloadLogo from '../../resources/more/goole.download.png'
import AppleDownloadLogo from '../../resources/more/apple.download.png'

export default function Footer() {
    return (
        <footer className={styles["main-footer"]}>
            <div className={styles.divider} ></div>
            <div className={styles["footer-container"]}>
                <div className={styles["footer-nav"]}>
                    <div className={styles["footer-row"]}>
                        <div className={styles["footer-col"]}>
                            <h1 className={styles.title}>Mapa del sitio</h1>
                            <ul className={styles.nav}>
                                <li>
                                    <Link to="/">
                                        Inicio
                                    </Link>
                                </li>
                                <li>
                                    <Link to="about-us">
                                        Nosotros
                                    </Link>
                                </li>
                                <li>
                                    <Link to="politics">
                                        Pol&iacute;ticas
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        Iniciar sesi&oacute;n
                                    </Link>
                                </li>
                            </ul>
                            <div className={styles.social}>
                                <a href="https://api.whatsapp.com/message/JTRQIFHYITQKI1?autoload=1&app_absent=0" target='_blank' rel="noreferrer noopener"><WhatsApp /></a>
                                <a href="https://www.facebook.com/sq.nutricion" target='_blank' rel="noreferrer noopener"><FacebookRounded /></a>
                                <a href="https://www.instagram.com/sq.nutricion/" target='_blank' rel="noreferrer noopener"><Instagram /></a>
                                <a href="mailto:info@sqnutricion.com" target="_blank" rel="noopener noreferrer"><Email /></a>
                            </div>
                            <div className={styles.download} style={{ display: "none" }}>
                                <a href="http://">
                                    <img src={GoogleDownloadLogo} alt="Google download" width={200} />
                                </a>
                                <a href="http://">
                                    <img src={AppleDownloadLogo} alt="Google download" width={200} />
                                </a>
                            </div>
                        </div>
                        <div className={styles["footer-col"]}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d954.7537981492169!2d-83.93300047312549!3d9.857021112280423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0df4f3593a34d%3A0x70762099ed229719!2sSQ%20Nutrici%C3%B3n!5e0!3m2!1sen!2scr!4v1718083206471!5m2!1sen!2scr"
                                width="800" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='SQ Location'>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}