import logo from '../../resources/logo/LogotipoSQ_Azul_SinFondo.png'
import Check from '../../resources/svg/check.svg'
import { Button, ThemeProvider, createTheme } from "@mui/material";
import styles from "./PlanOption.module.css"

export default function PlantOption(props) {

    const theme = createTheme({ palette: { sqnutrition: { main: '#E48873', contrastText: '#FFF', } } });

    return (
        <div className={styles.plan}>
            <div className={styles["plan-title"]}>
                <img src={logo} width={95} height={55} alt='SQ LOGO'></img>
                <p>{props.title}</p>
            </div>
            <div className={styles["plan-subtitle"]}>
                {(
                    (props.subtitle) ? <p>{props.subtitle}</p> : <span></span>
                )}

            </div>
            <div className={styles["plan-price"]}>
                <p>₡{props.price}</p>
            </div>
            <div className={styles["plan-body"]}>
                <ul>
                    {
                        props.characteristics.map((option, key) => {
                            return (
                                <li key={key}>
                                    <img src={Check} alt="Check" />
                                    <p>{option}</p>
                                </li>
                            )
                        })
                    }

                </ul>
            </div>
            <div className={styles["plan-footer"]}>
                {props.extras &&
                    props.extras.map((item, key) => {
                        return <p key={key} className={styles['extras']}>{item}</p>
                    })

                }
                {props.withButton === "true" &&
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" color="sqnutrition" size="small">
                            Obtener plan
                        </Button>
                    </ThemeProvider>
                }
            </div>
        </div>
    )
}