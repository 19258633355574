import { useState } from 'react';
import style from "./FloatingMenu.module.css"
import { Fab, ThemeProvider, createTheme } from "@mui/material"
import { Telegram, WhatsApp, FacebookRounded, Instagram } from "@mui/icons-material"
import { green } from "@mui/material/colors"



export default function FloatingMenu() {

    const [activeFloating, setactiveFloating] = useState(false);

    const handlerClick = () => {
        setactiveFloating(!activeFloating)
    }

    const { palette } = createTheme();

    const theme = createTheme({
        palette: {
            whatsappColor: palette.augmentColor({ color: green }),
            sqColor: palette.augmentColor({ color: { main: "#E48873" } }),
            instagramColor: palette.augmentColor({ color: {main: "#8134AF"} })
        }
    })

    const floating = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    };

    const floatingWhastapp = {
        ...floating,
        bottom: 230
    }

    const floatingInstagram = {
        ...floating,
        bottom: 160
    }

    const floatingFacebook = {
        ...floating,
        bottom: 90
    }

    return (
        <ThemeProvider theme={theme}>
            <a className={style["floating-option"] + ' ' + (activeFloating ? style["floating-option-active"] : undefined)} href="https://api.whatsapp.com/message/JTRQIFHYITQKI1?autoload=1&app_absent=0" target='_blank' rel="noreferrer noopener">
                <Fab color="whatsappColor" style={floatingWhastapp} aria-label="whatsapp">
                    <WhatsApp sx={{ color: "white" }} />
                </Fab>
            </a>
            <a className={style["floating-option"] + ' ' + (activeFloating ? style["floating-option-active"] : undefined)} href="https://www.instagram.com/sq.nutricion/" target='_blank' rel="noreferrer noopener">
                <Fab color="instagramColor" style={floatingInstagram} aria-label="instagram">
                    <Instagram sx={{ color: "white" }} />
                </Fab>
            </a>
            <a className={style["floating-option"] + ' ' + (activeFloating ? style["floating-option-active"] : undefined)} href="https://www.facebook.com/sq.nutricion" target='_blank' rel="noreferrer noopener">
                <Fab color="primary" style={floatingFacebook} aria-label="facebook" >
                    <FacebookRounded sx={{ color: "white" }} />
                </Fab>
            </a>
            <Fab onClick={handlerClick} color="sqColor" style={floating} aria-label="message" >
                <Telegram sx={{ color: "white" }} />
            </Fab>
        </ThemeProvider>
    )
}