import styles from "./Politics.module.css"

export default function Politics() {
    return (
        <div className={styles.politics}>
            <h1 className={styles["main-title"]}>Pol&iacute;ticas</h1>
            <article className={styles.remainder}>
                <h3>Recordatorio</h3>
                <p>
                    SQNutricion se compromete a tener un <b>trato humano</b> con los clientes en temas de comunicaci&oacute;n, agendar citas, asesor&iacute;as;
                    logrando as&iacute;, darte una experiencia más <b>personalizada.</b>
                </p>
            </article>
            <article className={styles.calendar}>
                <h3>¿C&oacute;mo agendar su cita?</h3>
                <ol className={styles["calendar-items"]}>
                    <li className={styles["calendar-item"]}>
                        La cita se debe agendar mediante el n&uacute;mero <b>8755-0308</b>
                    </li>
                    <li className={styles["calendar-item"]}>
                        El contacto se debe realizar v&iacute;a Whatsapp. <b>No llamadas.</b>
                    </li>
                    <li className={styles["calendar-item"]}>
                        Se debe realizar un pago parcial de <b>10,000 colones como adelanto para reservar su espacio</b>, ese monto se le descuenta del valor total de la cita.
                    </li>
                    <li className={styles["calendar-item"]}>
                        Una vez realizado el pago del adelanto se acuerda la fecha y la hora de la cita.
                    </li>
                </ol>
                <span className={styles.note}><b>*Aplica para pacientes nuevos y pacientes de control</b></span>
            </article>
            <article className={styles.cancellation}>
                <h3>Pol&iacute;ticas de cancelaci&oacute;n de citas</h3>
                <ul>
                    <li>
                        La cancelación de citas debe realizarse a más tardar a las <b>12:00 del mediodía del día anterior a la misma.</b>
                    </li>
                    <li>
                        Es importante que tenga en cuenta que si la cancelación no se realiza en este periodo de tiempo, no se le podrá hacer una devolución del adelanto.
                    </li>
                    <li>
                        Si la cancelación se realiza de acuerdo a lo estipulado se le reintegrará el adelanto.
                    </li>
                </ul>

                <div className={styles.notes}>
                    <span className={styles.note}><b>*En caso de tener que reintegrar el adelanto, se realizaría en un plazo de 24-48 horas máximo</b></span>
                    <span className={styles.note}><b>*En caso de realizar 2 reprogramaciones seguidas, para la tercera reprogramación se le solicitará el 50% de la consulta por adelantado.</b></span>
                </div>
            </article>
            <article className={styles.appointment}>
                <h3>Indicaciones para el d&iacute;a de la cita</h3>
                <ol className={styles["appointment-list"]}>
                    <li>
                        Ser puntual. En caso de llegar tarde a su cita:
                    </li>
                    <ul className={styles["appointment-nested-list"]}>
                        <li>
                            Si es menor a 15 minutos de demora, se le destinará el tiempo restante.
                        </li>
                        <li>
                            Si son más de 20 minutos, la cita queda cancelada.
                        </li>
                    </ul>
                    <li>
                        Asistir en completo ayuno o con 3 horas de no comer ni beber nada, ni agua.
                    </li>
                    <li>
                        Evitar realizar ejercicios o actividades físicas en un rango de 8 a 12 horas.
                    </li>
                    <li>
                        Procure andar sin crema o aceites en pies y/o manos.
                    </li>
                    <li>
                        Vista ropa liviana, preferiblemente ropa deportiva.
                    </li>
                    <li>
                        Por favor no presentarse si tiene síntomas de gripe o resfriado, cancelar con anticipación.
                    </li>
                    <li>
                        El pago es en efectivo, por transferencia anticipada o por Sinpe Móvil.
                    </li>
                </ol>
            </article>
            <article className={styles.disclaimer}>
                <h3>Disclaimer</h3>
                <p>
                    Si es mujer y se encuentra en los primeros 3 o 4 días de su periodo es recomendable reagendar la cita, ya que la medición puede verse alterada.
                </p>
                <span className={styles.note}><b>*Por favor dentro de lo que se pueda hacerlo con anticipación.</b></span>
            </article>
        </div>
    );
}