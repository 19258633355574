import { Button } from "@mui/material"
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function NotFound() {
    const theme = createTheme({
        palette: {
            sqnutrition: {
                main: '#DEC3C3',
                contrastText: '#323745',
            },
        },
    });

    return (


        <div className="notFound">            
            <h1>404</h1>
            <p>No se ha encontrado la p&aacute;gina solicitada</p>
            <ThemeProvider theme={theme}>
                <Button href="/" variant="contained" color="sqnutrition">Ir al inicio</Button>
            </ThemeProvider>
        </div>
    )
}