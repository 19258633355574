/* ROUTER */
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'

/* PAGES */
import Home from './components/Home/Home';
import About from './components/About/About';
import Politics from './components/Politics/Politics';
import Plan from './components/Plan/Plan';
import NotFound from './pages/NotFound';

/* LAYOUTS*/
import RootLayout from './layout/RootLayout';

import './App.css';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path='about-us' element={<About />} />
      <Route path='politics' element={<Politics />} />
      <Route path='plans' element={<Plan />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
