import styles from "./Home.module.css"
import banner from "../../resources/more/banner_edited.png"
import workModelImg from "../../resources/more/image-saray.jpg"
import reviewImage from "../../resources/more/image-saray-2.png"
import AppImage from "../../resources/more/image-saray-11.png"
import Discipline from "../../resources/more/image-saray-9.png"
import Vision from "../../resources/more/image-saray-5.jpeg"
import Mision from "../../resources/more/image-saray-6.jpg"
import Value from "../../resources/more/image-saray-7.jpg"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Button } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const theme = createTheme({
    palette: {
        sqnutrition: {
            main: '#DEC3C3',
            contrastText: '#323745',
        },
    },
});
export default function Home() {
    return (
        <div className={styles.home}>
            <div className={styles.title}>
                <img src={banner} alt="SQ NUTRICION" />
            </div>
            <div className={styles.phrase}>
                <p>No se trata de la meta</p>
                <p>Se trata de crecer y convertirte en </p>
                <p>esa persona que pueda lograr esa meta</p>
                <div className={styles.button}>
                    <ThemeProvider theme={theme}>
                        <Link to="/about-us">
                            <Button variant="contained" color="sqnutrition" size="medium" className={styles["phrase-button"]}>CONOC&Eacute; DE NOSOTROS</Button>
                        </Link>
                        <Button href="https://api.whatsapp.com/message/JTRQIFHYITQKI1?autoload=1&app_absent=0" target="_blank" variant="contained" color="sqnutrition" size="medium" className={styles["phrase-button"]}>Hac&eacute; una cita</Button>
                    </ThemeProvider>

                </div>
            </div>
            <section className={styles.info}>
                <h1>Nuestro modelo de trabajo</h1>
                <div className={styles.body}>
                    <div className={styles.text}>
                        El propósito de SQ es fomentar la autoconfianza en las personas, permitiéndoles disfrutar del proceso y aprender a amarse a sí mismas, prestando atención a las señales de su cuerpo y adquiriendo habilidades para una alimentación saludable a través del consumo responsable de alimentos.
                    </div>
                    <img src={workModelImg} alt="Saray" />
                </div>
            </section>
            <section className={styles.info}>
                <h1>Disciplina</h1>
                <div className={styles.body + ' ' + styles["bg-pink"] + ' ' + styles.reverse}>
                    <div className={styles.text}>
                        <p>Puede resultar difícil mantenerte enfocado y motivado cuando las cosas se ponen difíciles o cuando simplemente no salen como vos querés, pero creeme que es ahí cuando más determinación debés tener para poder seguir adelante.</p>
                        <p>La disciplina no se trata solo de trabajar durísimo, sino, también de mantener el enfoque y la consistencia en todas nuestras acciones diarias.</p>
                        <p>Sé que con dedicación, enfoque y consistencia podés desarrollar esa disciplina necesaria para convertirte en tu mejor versión.</p>
                    </div>
                    <img src={Discipline} alt="Saray" />
                </div>
            </section>
            <section className={styles.info}>
                <h1>Visi&oacute;n</h1>
                <div className={styles.body + ' ' + styles["bg-orange"]}>
                    <div className={styles.text}>
                        Nuestra visión es convertirnos en un centro integral que promueva el desarrollo y la motivación de las personas, impulsando la consecución de los objetivos individuales de nuestros clientes.
                    </div>
                    <img src={Vision} alt="Saray" />
                </div>
            </section>
            <section className={styles.info}>
                <h1>Valores</h1>
                <div className={styles.body + ' ' + styles.reverse}>
                    <div className={styles.text}>
                        <ol>
                            <li className={styles['m-bottom']}>Ofrecemos excelencia adaptada a las necesidades individuales de cada uno de nuestros clientes.</li>
                            <li className={styles['m-bottom']}>Mantenemos la constancia y la disciplina, siendo coherentes en nuestro compromiso y lo que hacemos.</li>
                            <li>Ponemos al cliente en el centro de todo lo que hacemos, priorizando su orientación y satisfacción.</li>
                        </ol>
                    </div>
                    <img src={Value} alt="Saray" />
                </div>
            </section>
            <section className={styles.info}>
                <h1>Misi&oacute;n</h1>
                <div className={styles.body + ' ' + styles["bg-pink"]}>
                    <div className={styles.text}>
                        Inspirar y educar a las personas para que cultiven el hábito de una alimentación saludable a través del amor propio, la integralidad y la consecución de sus objetivos personales.
                    </div>
                    <img src={Mision} alt="Saray" />
                </div>
            </section>

            {/*TODO: CREATE REVIEWS WITH ITS FUNCTIONALITY [ARRAY] */}
            <section className={styles.info + ' ' + styles['d-none']}>
                <h1>Rese&ntilde;as</h1>
                <div className={styles.reviews}>
                    <div className={styles["review-title"]}>
                        <AccountCircleIcon sx={{ color: "black" }} />
                        <p>Daniel Coto Quir&oacute;s</p>
                    </div>
                    <div className={styles["review-body"]}>
                        <p>Puede resultar difícil mantenerte enfocado y motivado cuando las cosas se ponen difíciles o cuando simplemente no salen como vos querés,pero creeme que es ahí cuando más determinación debés tener para poder seguir adelante.</p>
                    </div>
                </div>
                <div className={styles.reviews}>
                    <div className={styles["review-title"]}>
                        <AccountCircleIcon sx={{ color: "black" }} />
                        <p>Wilheln Cerdas</p>
                    </div>
                    <div className={styles["review-body"]}>
                        <p>Me ha parecido estupendo el trabajo que hace Saray Quiros Coto.</p>
                    </div>
                </div>
            </section>

            <section className={styles.info}>
                <h1>Informaci&oacute;n</h1>
                <div className={styles.cards}>
                    <div className={styles.card}>
                        <div className={styles["card-title"]}>
                            <p>Dejanos tu opini&oacute;n</p>
                        </div>
                        <div className={styles["card-body"]}>
                            <img src={reviewImage} alt="Rese&ntilde;as" />
                            <p>¡Queremos conocer tu experiencia con nostros!</p>
                        </div>
                        <div className={styles["card-footer"]}>
                            <ThemeProvider theme={theme}>
                                <Button href="https://www.instagram.com/sq.nutricion/" target='_blank' variant="contained" color="sqnutrition" size="large">Ingres&aacute; aqu&iacute;</Button>
                            </ThemeProvider>
                        </div>
                    </div>
                    <div className={styles.card}>
                        <div className={styles["card-title"] + ' ' + styles.app}>
                            <p>Reserv&aacute; una cita</p>
                        </div>
                        <div className={styles["card-body"]}>
                            <img src={AppImage} alt="Citas" />
                            <p>¡Pregunt&aacute; a nuestros expertos sobre nutrici&oacute;n!</p>
                        </div>
                        <div className={styles["card-footer"]}>
                            <ThemeProvider theme={theme}>
                                <Button href="https://api.whatsapp.com/message/JTRQIFHYITQKI1?autoload=1&app_absent=0" target='_blank' variant="contained" color="sqnutrition" size="large">
                                    Consult&aacute; aqu&iacute;
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>

            </section>
        </div >
    );
}